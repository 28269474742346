.cFooterWrapper{
    width: 100%;
    margin-top: 5rem;

}
.cFooterWrapper>hr{
    width: 100%;
    height: 1px;
    border: none;
    background: white;
    height: 2px;
}
.cFooter{
    display: flex;
    width: 100%;
    justify-content: space-around;

}
.logo{
    display: flex;
    align-items: flex-start;
    gap: 1rem;
}
.logo>img{
    width: 2.5rem;
    height: 2.5rem;

}
.logo>span{
    font-weight: 600;
    position: relative;
    top: 9px;
    left: -7px;
}
.block{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 12rem;
}
.detail{
    display: flex;
    flex-direction: column;
    width: inherit;
    font-size: .7rem;
    gap: 1rem;
    font-style: italic;
}
.detail>span:nth-of-type(1){
    font-weight: 500;
    font-size: 1.2rem;
    font-style: normal;

}
.pngLine:hover{
    cursor: pointer;

}
.pngLine{
    display: flex;
    gap: 1rem;
    align-items: center;
}
.icon{
    width: 25px;
}

.copyRight{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.65rem;
    margin-top: 2rem;
    width: 100%;
    flex-direction: column;
}