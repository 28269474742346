.container {
  padding: 1.5rem;
  display: flex;
}

.logo {
  display: flex;
  align-items: center;
  flex: 1;
  gap: 0.3rem;
}

.logo > img {
  width: 2.5rem;
  height: 2.5rem;
}

.logo > span {
  font-weight: 600;
}

.right {
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}

.menu {
  display: flex;
  gap: 2rem;
  font-weight: 500;
  list-style: none;
}

.menu > li:hover {
  color: rgb(218, 182, 4);
  cursor: pointer;
}
.search{
    width: 9rem;
    height: 1.8rem;
    outline: none;
    border: none;
    border-radius: 14px;
}
.cart{
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    position: relative;
    left: -28px;
}