.testimonials{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4rem 2rem;
}
.wrapper{
    display: flex;
    
    align-items: center;
    justify-content: center;
    /* flex-direction: column; */
    /* display: grid;
    align-items: flex-end;
    width: 100%;
    grid-template-columns: 1fr 2fr 1fr; */
}
.wrapper>:nth-child(1){
    display: block;
   text-transform: uppercase;
   font-size: 2rem;
   font-weight: bold;
   white-space: nowrap;
   
    
  

}
.wrapper>:nth-child(3){
 position: relative;
 left: -173px;
 top: 4rem;
 font-size: 15px;
 /* white-space: nowrap; */

  
}
.wrapper>img{
    position: relative;
    left: -109px;
    width: 25rem;
    justify-self: center;
    align-items: center;
}
.container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.container>:nth-child(1)
{
    font-size: 2rem;
    text-transform: uppercase;
    display: block;
    /* font-weight: bold; */
}
.container>:nth-child(2){
    font-size: 0.8rem;
    text-transform: uppercase;
    display: block;
}
.reviews{
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
}
.carousel{
    width: 100%;

}
.tCarousel{
    padding: 2rem;
}
.testimonial{
    display: flex;
    flex-direction: column;
    position: relative;
    background: white;
    border-radius: .5rem;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    gap: 1rem;
    box-shadow: 0rem 1rem 3rem -50px #7d7d7d;
}
.testimonial>img{
    position: absolute;
    width: 3rem;
    top: -24px;
    left: 45%;
}
.testimonial>span:nth-of-type(1){
    align-items: center;
    font-size: 0.8rem;
    letter-spacing: 1px;
    margin-top: 2rem;
}
.testimonial>hr{
    height: 1px;
    width: 80%;
    background: white;
    border: none;
}